<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款单号">
              <a-input v-model="queryParam.serial_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.order_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="接单日期">
              <a-range-picker @change="onDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <!--          <a-col v-bind="GLOBAL.queryColSpan">-->
          <!--            <a-form-item label="委托客户">-->
          <!--              <a-select-->
          <!--                showSearch-->
          <!--                label-in-value-->
          <!--                allowClear-->
          <!--                placeholder="请选择委托客户"-->
          <!--                style="width: 100%"-->
          <!--                :value="customerInfo"-->
          <!--                :filter-option="false"-->
          <!--                :not-found-content="fetching ? undefined : null"-->
          <!--                @search="getDepartmentCustomer"-->
          <!--                @change="changeCustomerInfo"-->
          <!--              >-->
          <!--                <template v-if="fetching" #notFoundContent>-->
          <!--                  <a-spin size="small" />-->
          <!--                </template>-->
          <!--                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>-->
          <!--              </a-select>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款对象">
              <a-select
                v-model="queryParam.customer_id"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in this.supplierOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input placeholder="根据发票号进行搜索" v-model="queryParam.invoice_number" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                allowClear
                placeholder="请选择费用状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <div class="table-operator">
              <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <!--          <a @click="handlePayBills(record, index)">查看</a>-->
          <!--          <a-divider type="vertical" />-->
          <a @click="handleDelete('row', record)">删除</a>
          <a-divider type="vertical" />
          <template v-if="record.fee_status === 4">
            <a @click="handleApplyBizAudit(record, index)">重新申请</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="(typeof record.audit_id !== 'undefined')">
            <a @click="handlePrint(record, index)">付款单</a>
            <a-divider type="vertical" />
          </template>
          <a @click="handleBindInvoice(record, index)">关联发票号</a>
        </template>
      </span>
      <div slot="paymentInfoItem" slot-scope="text, record">
        <span>{{ record.customer_name }}</span><br/>
        <span>{{ record.payment_num }}</span>
      </div>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '未提交' }}
        </a-tag>
      </div>
      <div slot="payInfoSlots" slot-scope="text, record">
        <span>{{ record.currency_name }}</span>
        <span>
          <a-tag :color="record.is_advance === 1 ? 'green' : 'red'" style="margin-bottom:2px;">
            {{ record.is_advance === 1 ? '后付' : '预付' }}
          </a-tag>
        </span>
        <span>{{ paymentMap[record.pay_method] ? paymentMap[record.pay_method].name : '' }}</span>
      </div>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.fees" :key="item.id">
          <span v-if="item.pay_target_name">{{ item.pay_target_name }}{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
          <span v-else>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <div slot="invoiceItem" slot-scope="text, record">
        <div v-for="item in record.pay_invoices" :key="item.id">
          <span>发票号：{{ item.invoice_number }}税率：{{ item.tax_rate }}</span>
        </div>
      </div>
    </s-table>
    <a-modal
      :visible="applyInvoiceVisible"
      :confirmLoading="applyInvoiceConfirmLoading"
      title="开票申请"
      :width="720"
      @cancel="applyInvoiceCancel"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="applyInvoiceForm" :rules="applyInvoiceRules" ref="applyInvoiceForm">
        <a-row :gutter="20">
          <div>
            <a-divider orientation="left">订单信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位">
                <a-input v-model="bizAuditForm.customer" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="所属部门">
                <a-input v-model="bizAuditForm.department" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="录入人">
                <a-input v-model="bizAuditForm.creator" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="申请时间">
                <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
              </a-form-model-item>
            </a-col>
          </div>
          <a-divider orientation="left"
          >费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
          </a-divider>
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
            style="padding-left:10px;padding-bottom:10px;"
          >
            全选
          </a-checkbox>
          <a-col :span="24">
            <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
              <a-row v-for="item in bizAuditForm.fees" :key="item.id">
                <a-alert
                  :message="`业务编号:${item.quotaNum}`"
                  type="info"
                  show-icon
                  style="margin-bottom:10px;"
                />
                <a-col
                  :span="8"
                  v-for="fee in item.feeList"
                  :key="fee.id"
                  style="padding-left:15px;padding-bottom:10px;"
                >
                  <a-checkbox :value="fee.id"> {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-col>
          <div>
            <a-col :span="24">
              <a-form-model-item label="申请备注">
                <a-textarea v-model="applyInvoiceForm.apply_remark"></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">开票信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位/客户名称" prop="customer_name">
                <a-input v-model="applyInvoiceForm.customer_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="纳税人识别号" prop="tax_no">
                <a-input v-model="applyInvoiceForm.tax_no" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="地址">
                <a-input v-model="applyInvoiceForm.address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="电话">
                <a-input v-model="applyInvoiceForm.mobile" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="银行卡号">
                <a-select v-model="applyInvoiceForm.bank_card_no" @change="bankAccountChange">
                  <a-select-option
                    :value="item.account"
                    v-for="item in bankAccountOps"
                    :key="item.account">
                    {{ item.account }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户银行">
                <a-input v-model="applyInvoiceForm.bank" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="税率" prop="tax_rate">
                <a-select v-model="applyInvoiceForm.tax_rate">
                  <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </div>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="businessAuditFormVisible"
      :confirmLoading="businessAuditConfirmLoading"
      title="商务审核申请"
      :width="720"
      @cancel="billOrverifyCancel"
      @ok="billOrVerifySubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <div>
          <a-divider orientation="left">基础信息</a-divider>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="付款方式" prop="pay_method">
                <a-select v-model="form.pay_method">
                  <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="附件数" prop="appendix_num">
                <a-input suffix="张" v-model="form.appendix_num"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="发票号码" prop="invoice_number">
            <a-select
              showSearch
              mode="multiple"
              ref="invoice_number"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择发票号码"
              style="width: 100%"
              v-model="form.invoice_num"
            >
              <a-select-option v-for="op in invoiceRegOps" :key="op.id"
              >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="合同号" prop="contract_list">
            <a-select
              showSearch
              mode="multiple"
              ref="contract_list"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择合同号"
              style="width: 100%"
              v-model="form.contract_list"
            >
              <a-select-option v-for="op in leaseInfoList" :key="op.id">
                合同号:{{ op.contract_no }}
                <span v-if="op.is_no_term===0 && !op.contract_termination_date">/ 截至日期:{{ moment(op.contract_end_date).format('YYYY/MM/DD') }}</span>
                <span v-if="op.is_no_term===0 && op.contract_termination_date">/顺延日期:{{ moment(op.contract_termination_date).format('YYYY/MM/DD') }}</span>
                / {{ op.customer_info.customer_cn_name }}
                <span v-if="op.credit_period">/ 信用期：{{ op.credit_period }}天</span>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
        </a-divider>
        <a-row>
          <a-row v-for="item in feeOrderList" :key="item.id">
            <a-alert :message="`订单编号:${item.orderNums}`" type="info" show-icon style="margin-bottom:10px;" />
            <a-col :span="8" v-for="fee in item.feeList" :key="fee.id" style="padding-left:15px;padding-bottom:10px;">
              <span>{{ fee.pay_target_name }} {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}</span>
            </a-col>
          </a-row>
        </a-row>
        <div>
          <a-form-model-item label="申请备注" prop="apply_remark">
            <a-textarea v-model="form.apply_remark"></a-textarea>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord"
        >
        </fms-pay-bill>
      </div>
      <template slot="footer">
        <a-button
          id="printPayBillBtn"
          key="submit"
          type="primary"
          v-print="printPayBill">确认打印
        </a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <fms-pay-state-bill
          :currentData="currentData"
          :balanceData="balanceData"
        ></fms-pay-state-bill>
      </div>
      <template slot="footer">
        <a-button
          id="printStatementsPayBillBtn"
          key="submit"
          type="primary"
          v-print="printStatementsPayBill"
        >确认打印
        </a-button>
        <a-button type="primary" @click="printPayStatementsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="invoiceModel"
      :confirmLoading="invoiceModelLoading"
      :width="720"
      :maskClosable="false"
      title="关联发票"
      @cancel="invoiceModel = false"
      @ok="handleBindInvoiceSubmit"
    >
      <bind-invoice ref="bindInvoice" />
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { ItmsFinanceFunc } from '@/api/itms'
import { CumsFinanceFunc, getCumsPayBillFunc } from '@/api/cums'
import { filterOption } from '@/utils/util'
import { getCommonNoPage, getCustomer, getCustomerInfo, getSupplier, getLeaseInfoListByDepartment } from '@/api/common'
import moment from 'moment';
import { fmsBizAuditFunc, getBizAuditPage, getFmsInfoDetail, getchargeTax } from '@/api/fms'
import fmsPayBill from '@/views/fms/bill/FmsPayBill';
import fmsPayStateBill from '@/views/fms/bill/FmsPayStatementBill';
import bindInvoice from '@/views/components/BindInvoice';
import debounce from 'lodash/debounce'
export default {
  name: 'ItmsPayBill',
  components: {
    STable,
    fmsPayStateBill,
    fmsPayBill,
    bindInvoice
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      balanceData: [],
      checkedBalanceList: [],
      balanceRecord: {},
      blanceFeeVisible: false,
      blanceFeeLoading: false,
      invoiceModel: false,
      invoiceModelLoading: false,
      queryParam: {},
      leaseInfoList: [],
      feeStatusList: [
        { value: 1, name: `待审核` },
        { value: 2, name: `业务已审核` },
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 9, name: '商务已审核(待付款)' },
        { value: 13, name: '已付款' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'order_nums',
          width: 200,
          fixed: 'left',
          customRender: (text) => {
            return text.join('\n')
          }
        },
        {
          title: '委托客户',
          fixed: 'left',
          dataIndex: 'customer_name',
          scopedSlots: { customRender: 'paymentInfoItem' }
        },
        {
          title: '付款状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: '接单日期',
          dataIndex: 'receiving_order_dates',
          width: 130,
          customRender: (text) => {
            if (typeof text !== 'undefined') {
              const names = text.map(i => {
                return moment(i).format('YYYY/MM/DD')
              })
              return names.join('\n')
            } else {
              return ''
            }
          }
        },
        {
          title: '付款信息',
          dataIndex: 'pay_info',
          scopedSlots: { customRender: 'payInfoSlots' }
        },
        {
          title: '备注',
          width: 100,
          dataIndex: 'remark'
        },
        {
          title: '付款费用',
          dataIndex: 'fees',
          width: 400,
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '发票信息',
          dataIndex: 'pay_invoices',
          width: 200,
          scopedSlots: { customRender: 'invoiceItem' }
        },
        {
          title: '付款银行',
          dataIndex: 'pay_bank',
          customRender: text => {
            return text && `${text.bank_describe}/${text.bank_account}`
          }
        },
        {
          title: '付款时间',
          dataIndex: 'pay_date',
          customRender: text => {
            return text && text.substring(0, 10)
          }
        },
        {
          title: '创建人',
          width: 100,
          dataIndex: 'create_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getCumsPayBillFunc('get_cums_payment_page', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      businessAuditFormVisible: false,
      businessAuditConfirmLoading: false,
      defaultDate: [],
      selectedRowKeys: [],
      taxRateOps: [],
      applyInvoiceVisible: false,
      applyInvoiceConfirmLoading: false,
      indeterminate: false,
      checkAll: true,
      totalAmount: '',
      selectedRows: [],
      checkedList: [],
      feeOrderList: [],
      bankAccountOps: [],
      invoiceRegOps: [],
      // 商务审核申请form
      form: {
        pay_method: '',
        appendix_num: null,
        apply_remark: '',
        invoice_num: []
      },
      // 开票申请form
      applyInvoiceForm: {
        apply_remark: '',
        customer_name: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: ''
      },
      bizAuditForm: {
        audit_record_id: '',
        customer: '',
        department: '客服部',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      paymentMap: {
        1: { value: 1, name: '转账' },
        2: { value: 2, name: '支票' },
        3: { value: 3, name: '代付' },
        4: { value: 4, name: '打卡' },
        5: { value: 5, name: '现金' },
        6: { value: 6, name: '内部结算' }
      },
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      applyInvoiceRules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_no: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }]
      },
      rules: {
        apply_remark: [{ required: true, message: '请输入申请备注', trigger: 'blur' }],
        pay_method: [{ required: true, message: '请选择付款方式', trigger: 'change' }]
      },
      balanceFeeIds: [],
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printData: [],
      orderType: '客服部',
      payment_id: '',
      audit_id: null,
      printPayBillResult: [],
      printPayBillRecord: {},
      auditId: null,
      customerInfo: undefined,
      fetching: false,
      customerOps: [],
      supplierOps: [],
      currentData: {},
      feeStatusMaps: {
        1: { value: 1, name: '待审核', color: '#faad14' },
        2: { value: 2, name: '业务已审核', color: '#1890ff' },
        3: { value: 3, name: '商务待审核', color: '#4cd964' },
        4: { value: 4, name: '商务审核被驳回', color: '#ff4d4f' },
        5: { value: 5, name: '待开票', color: '#ffe8e9' },
        6: { value: 6, name: '已开票', color: '#f8fadf' },
        9: { value: 9, name: '商务已审核(待付款)', color: '#e7ffec' },
        13: { value: 13, name: '已付款', color: '#e7ffec' }
      }
    }
  },
  methods: {
    moment,
    filterOption,
    // 关联发票model
    // 获得委托客户
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.queryParam['customer_id'] = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.queryParam['customer_id'] = null
        this.customerOps = []
        this.fetching = false
      }
    },
    handleBindInvoice(record) {
      this.auditId = record.audit_id
      this.invoiceModel = true
    },
    // 提交发票
    handleBindInvoiceSubmit() {
      this.$confirm({
        title: '确认提示',
        content: '确定要关联发票？',
        onOk: () => {
          const data = {
            audit_id: this.auditId,
            invoice_list: this.$refs.bindInvoice.invoice_num
          }
          fmsBizAuditFunc('bind_invoice', data).then((v) => {
            if (v) {
              this.$message.success('关联发票成功')
              this.invoiceModel = false
              this.$refs.table.refresh(false);
              this.$refs.bindInvoice.invoice_num = []
              this.$refs.bindInvoice.initData()
            }
          })
        }
      })
    },
    // 付款对账单
    handlePayBills(record) {
      if (typeof record.audit_id !== 'undefined') {
        this.balanceData = []
        this.currentData = record
        // 打印付款对账单
        getBizAuditPage('pay_list_balance', { id: record.audit_id }).then(res => {
          // 统计数据
          let pay = 0
          let profit = 0
          res.balance_item_list.forEach(v => {
            v.pay_fees &&
            v.pay_fees.forEach(c => {
              c.feeTypeName = this.GLOBAL.feeStatusMaps[c.fee_status] ? this.GLOBAL.feeStatusMaps[c.fee_status].name : ''
              if (c.currency_name === 'CNY') {
                pay += c.amount
              } else {
                pay += c.exchange_amount
              }
            })
            profit += v.pay_total
          })
          this.balanceStatic = {
            pay: '应付总计:' + pay.toFixed(2),
            profit: '合计:' + profit.toFixed(2)
          }
          this.currentData.record_no = this.currentData.payment_num
          this.balanceData = res.balance_item_list
          this.currentData.pay_total = profit
          this.balanceCurrency = res.currency
          this.balanceExchangeRate = res.exchange_rate
          this.printPayStatementsBillModel = true
        });
      } else {
        this.$message.error('当前订单尚未提交商务审核~')
      }
    },
    handlePrint(record) {
      // 打印付款单
      this.currentData = record
      getFmsInfoDetail('business_audit', { id: record.audit_id }).then(v => {
        // 费用名称相同的订单进行合并
        const data = v
        const result = []
        data.forEach(item => {
          const feeList = item.pay_fee_list;
          if (typeof feeList !== 'undefined') {
            feeList.forEach(fee => {
              result.push(fee)
            })
          }
        })
        this.printPayBillResult = result
        this.currentData.currency = this.currentData.currency_name
        this.currentData.customer = this.currentData.customer_name
        this.printPayBillRecord = this.currentData
        this.printPayBillRecord['invoice_count'] = record.pay_invoices ? record.pay_invoices.length : 0
        this.printPayBillModel = true
      });
    },
    balanceFeeChange(value) {
      this.balanceFeeIds = value
    },
    delBalanceFee(index, type) {
      if (type === 'charge') {
        this.balanceRecord.charge_fees.splice(index, 1)
      } else {
        this.balanceRecord.pay_fees.splice(index, 1)
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam.start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam.start_date = null
        this.queryParam.end_date = null
      }
    },
    // 删除结算单
    handleDelete(type, record) {
      this.$confirm({
        title: `确认提示`,
        content: `确定要删除？`,
        onOk: () => {
          getCumsPayBillFunc('delete_cums_payment', { 'id': record.id }).then(res => {
            this.$notification['success']({
              message: `提示`,
              description: `删除成功`
            });
          }).finally(() => {
            this.$refs.table.refresh(false);
          });
        }
      });
    },
    blanceFeeCancel() {
      this.balanceFeeIds = []
      this.blanceFeeVisible = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record)
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        for (const item of changeRows) {
          this.selectedRows.push(item)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          for (const item of changeRows) {
            if (item.id === this.selectedRows[index].id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    handleBillApply(record) {
      this.checkedList = []
      const recordRow = [record]
      this.totalAmount = ''
      const ids = recordRow.map(r => {
        return r.customer_name
      })
      const uniqueIds = Array.from(new Set(ids))
      if (uniqueIds.length > 1) {
        this.$message.warning('请选择同一客户信息')
      } else {
        let flag = false
        const feeMaps = []
        const map = new Map()
        this.selectedRows.forEach(row => {
          const orderFee = {
            quotaNum: row.quota_nums,
            feeList:
              row.charge_fees &&
              row.charge_fees
                .filter(e => e.fee_status === 2 || e.fee_status === 4)
                .map(e => {
                  if (map.has(e.currency_name)) {
                    map.set(e.currency_name, e.money + map.get(e.currency_name))
                  } else {
                    map.set(e.currency_name, e.money)
                  }
                  if (e.fee_status === 2 || e.fee_status === 4) {
                    flag = true
                  }
                  this.checkedList.push(e.id)
                  return {
                    id: e.id,
                    feeName: e.fee_name,
                    money: e.money,
                    currency_name: e.currency_name
                  }
                })
          }
          feeMaps.push(orderFee)
        })
        if (flag) {
          this.bankAccountOps = []
          this.getCustomerOps(recordRow[0].customer_name)
          this.bizAuditForm = {
            ...this.bizAuditForm,
            customer: recordRow[0].customer_name,
            fees: feeMaps,
            creator: recordRow[0].create_name
          }
          this.payment_id = recordRow[0].id
          for (const key of map.keys()) {
            this.totalAmount += map.get(key).toFixed(2) + key + ','
          }
          this.applyInvoiceVisible = true
        } else {
          this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
        }
      }
    },
    // 提交开票
    handleApplyFormSubmit() {
      this.$refs.applyInvoiceForm.validate(valid => {
        if (valid) {
          const data = {
            ...this.applyInvoiceForm,
            id_list: this.checkedList
          };
          ItmsFinanceFunc('apply_ticket', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
          }).finally(() => {
            this.totalAmount = '';
            this.applyInvoiceVisible = false;
            this.$refs.table.refresh(false);
          });
        }
      })
    },
    handleApplyBizAudit(record) {
      // 提交商务审核
      this.checkedList = []
      const recordRow = [record]
      const feeMaps = []
      let flag = false
      const map = new Map()
      recordRow.forEach(v => {
        const orderFee = {
          orderNums: v.order_nums.join(','),
          feeList: v.pay_fees && v.pay_fees
            .filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
              if (map.has(e.currency_name)) {
                map.set(e.currency_name, e.money + map.get(e.currency_name))
              } else {
                map.set(e.currency_name, e.money)
              }
              // total += e.money ? e.money : 0
              if (e.fee_status === 2 || e.fee_status === 4) {
                flag = true
              }
              this.checkedList.push(e.id)
              return {
                id: e.id,
                feeName: e.fee_name,
                money: e.money,
                currency_name: e.currency_name,
                pay_target_name: e.pay_target_name
              }
            })
        }
        feeMaps.push(orderFee)
      })
      if (flag) {
        this.payment_id = recordRow[0].id
        this.audit_id = recordRow[0].audit_id
        this.feeOrderList = feeMaps
        for (const key of map.keys()) {
          this.totalAmount += map.get(key).toFixed(2) + key + ','
        }
        getCommonNoPage('invoice_registration', {}).then((v) => {
          this.invoiceRegOps = v
        })
        const idList = []
        idList.push(record.customer_id)
        if (idList) {
          getLeaseInfoListByDepartment({ 'department': 7, 'customer_id_list': idList }).then(v => {
            this.leaseInfoList = v
          })
        }
        this.businessAuditFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    billOrVerifySubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          CumsFinanceFunc('business_audit', {
            pay_method: this.form.pay_method,
            invoice_num: this.form.invoice_num,
            contract_list: this.form.contract_list,
            apply_remark: this.form.apply_remark,
            appendix_num: this.form.appendix_num,
            payment_id: this.payment_id,
            id_list: this.checkedList,
            audit_id: this.audit_id
          }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
          }).finally(() => {
            this.form.pay_method = ''
            this.form.appendix_num = null
            this.form.apply_remark = ''
            this.form.invoice_num = []
            this.businessAuditFormVisible = false;
            this.$refs.table.refresh(false);
          });
        }
      })
    },
    applyInvoiceCancel() {
      this.applyInvoiceVisible = false;
      this.totalAmount = '';
    },
    billOrverifyCancel() {
      this.businessAuditFormVisible = false;
      this.totalAmount = ''; // 每次关闭弹出框后将会将总金额进行重置
    },
    getCustomerOps(name) {
      getCustomerInfo({
        department: null,
        customerName: name
      }).then(res => {
        res.forEach(data => {
          this.applyInvoiceForm.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.applyInvoiceForm.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.applyInvoiceForm.address = typeof data.street !== 'undefined' ? data.street : ''
          this.applyInvoiceForm.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.applyInvoiceForm.bank_card_no = typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.applyInvoiceForm.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        })
      })
    },
    bankAccountChange(data) {
      this.bankAccountOps.forEach(item => {
        if (item.account === data) {
          this.applyInvoiceForm.bank = item.bank_name
        }
      })
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = ''
      const mapItem = new Map()
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              if (mapItem.has(item.feeList[j].currency_name)) {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money + mapItem.get(item.feeList[j].currency_name))
              } else {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money)
              }
            }
          }
        }
      }
      for (const key of mapItem.keys()) {
        this.totalAmount += mapItem.get(key).toFixed(2) + key + ','
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = ''
      const mapList = new Map()
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            if (mapList.has(e.currency_name)) {
              mapList.set(e.currency_name, e.money + mapList.get(e.currency_name))
            } else {
              mapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
        })
        for (const key of mapList.keys()) {
          this.totalAmount += mapList.get(key).toFixed(2) + key + ','
        }
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    getSupplier({ department: 7 }).then(res => {
      this.supplierOps = res
    })
  }
}
</script>

<style scoped>

.balanceTitle{
  font-size: 20px;
  font-weight: 600;
}

</style>
